import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    height: 80px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
    position:relative;
    z-index:9999;
    @media screen and (max-width: 768px) {
        height: 100px;
    }


`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0;
    max-width: 1100px;

`
export const NavLogo = styled(LinkR)`
    //color: #B05FFD;
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 0;
    font-weight: bold;
    text-decoration: none;
    transition: all ease 0.2s;

    &:hover {
        color: #B05FFD;
    }

`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        //position: absolute;
        //top: 0;
        //right: 0;
        margin-top: auto;
        margin-bottom: auto;
        margin-right:20px;
        //transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

`

export const NavMenu = styled.ul`
    display: flex;
    flex-direction: left;
    align-items: left;
    list-style: none;
    text-align: left;
    margin-right: 0;
    height: 80px;

    @media screen and (max-width: 768px) {
        display: none;
    }

`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    font-weight:600;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #B05FFD;
        
    }

    &:hover {
        color: #007AF8;
    }

    @media screen and (max-width: 768px) {
        margin:0 auto;
       
    }

`
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: none;
    }

`

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    text-decoration: none;
    border: 1px solid #333;
    white-space: nowrap;
    padding: 10px 22px;
    color: #ffffff;
    font-size: 1rem;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        color: #fff;
        box-shadow: rgba(0, 0, 229, 0.38) 0px -3px 30px;
        border: 1px solid #007AF8;
    }


`