import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
  FooterLinkToolTip,
} from "./FooterElements";
import "./../../App.css";
import AltLogo  from "../../assets/images/altstreet_logowhite.svg";
import { BsTwitter } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import Modal from 'react-modal';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "//altstreet.us14.list-manage.com/subscribe/post?u=dac7eb89aa0996eea2aba416e&amp;id=c42ee169cd";


// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

const customStyles = {
  overlay: {
   backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #333',
    background: '#000',
    borderRadius: '20px',
    padding: '40px',
    textAlign: 'center'
  },
};




Modal.setAppElement('#root');

const Footer = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  
  return (
    <FooterContainer>
      <FooterWrapper className="container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1 col-sm-12 my-auto px-5">
          <img src={AltLogo} width="150" /> <br />
          <small className="grayier-text">Copyright © 2022 AltstreetDAO</small>
          </div>

          <div className="col-lg-3 col-sm-12 my-auto px-2">
            <FooterH2 className="py-2">Protocol Info</FooterH2>
            <ul className="navbar-nav ">
              <li className="nav-item py-1"> <FooterLink href="https://www.linkedin.com/company/altstreet-dao/">About Us&nbsp;&nbsp;<BsLinkedin className="my-auto"/></FooterLink></li>
              <li className="nav-item py-1"> 
              <FooterLink onClick={openModal}>White Paper
              <FooterLinkToolTip className="tooltiptext">Coming Soon</FooterLinkToolTip>
              </FooterLink></li>
              <li className="nav-item py-1"> <FooterLink href="https://github.com/AltStreetDAO">Github&nbsp;&nbsp;<BsGithub className="my-auto"/></FooterLink></li>
              <li className="nav-item py-1"> <FooterLink href="https://medium.com/@altstreetDAO">Blog&nbsp;&nbsp;<BsMedium className="my-auto"/></FooterLink></li>
            </ul>
         </div>

         <div className="col-lg-3 col-sm-12 my-auto px-2">
            <FooterH2 className="py-2">Reach Us</FooterH2>
            <ul className="navbar-nav">
              <li className="nav-item py-1"> <FooterLink href="https://twitter.com/AltStreetDAO" >Twitter&nbsp;&nbsp;<BsTwitter className="my-auto"/></FooterLink></li>
              <li className="nav-item py-1"> <FooterLink href="#">Telegram&nbsp;&nbsp;<BsTelegram className="my-auto"/></FooterLink></li>
              <li className="nav-item py-1"> <FooterLink href="https://www.youtube.com/channel/UCqMn5RpRvc9YtCpNgwNCAWw">Youtube&nbsp;&nbsp;<BsYoutube className="my-auto"/></FooterLink></li>
              <li className="nav-item py-1"> <FooterLink href="https://www.instagram.com/AltstreetDAO/">Instagram&nbsp;&nbsp;<BsInstagram className="my-auto"/></FooterLink></li>
            </ul>
         </div>

        </div>

        <div>
      <div id="modalAltApp">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Newsletter"
        >
        
      
      <div className="newsform">
      <FooterH2>Stay Informed</FooterH2>
      <p>sign up to receive email updates on <br />new product announcements, promotions, and more.</p>
    

      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <div className="newsform-message">
            <SimpleForm onSubmitted={formData => subscribe(formData)} />
            {status === "sending" && <span >sending...</span>}
            {status === "error" && <span dangerouslySetInnerHTML={{__html: message}}/>}
            {status === "success" && <span >Subscribed !</span>}
          </div>
        )}
      />
       
       </div>

        </Modal>
      </div>
    </div>
      </FooterWrapper>
    </FooterContainer>

    
  );
};

export default Footer;
