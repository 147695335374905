import styled from "styled-components";

export const RoadmapContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    @media screen and (max-width: 768px) {
        min-height: 1100px;
    }

    @media screen and (max-width: 480px) {
        min-height: 1300px;
    }

`

export const RoadmapWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 20px 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 50px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr ;
        //padding: 0 20px;
        padding: 20px 50px;
    }

`

export const RoadmapCard = styled.div`
    background: #000;
    color:#fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    border: 1px solid #333;
    transition: all 0.2s ease-in-out;
    min-height:270px;
    
    
    &:hover {
        transform: scale(1.02);
        box-shadow: rgba(0, 0, 229, 0.38) 0px -3px 10px;
    }


    @media screen and (max-width: 768px) {
        min-height:auto;
    }

`

export const ServicesIcon = styled.img`
    height: 72px;
    width: 72px;
    margin-bottom: 10px;
    float:left;
`

export const RoadmapH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;
    text-align:center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
       
    }

`

export const RoadmapH2 = styled.h2`
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 900;
`

export const RoadmapP = styled.p`
    font-size: 14px;
    text-align: center;
    padding:0 42px;
`
