import React from "react";
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from "./ServicesElements";
import Icon1 from "../../assets/icons/icons8-realestate.svg"
import Icon2 from "../../assets/icons/icons8-corpbonds.svg"
import Icon3 from "../../assets/icons/icons8-gov.svg"
import Icon4 from "../../assets/icons/icons8-legal.svg"
import Icon5 from "../../assets/icons/icons8-musical.svg"
import Icon6 from "../../assets/icons/icons8-fineart.svg"

const Services = () => {
  return (
    <ServicesContainer id="realassets">
      <ServicesH1>Join the future of DeFi.</ServicesH1>
      <ServicesWrapper>

        
      <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2 className="h2-azul">Government Bonds</ServicesH2>
          <ServicesP>
          Generate sustainable, risk-adjusted yield from real assets.
          </ServicesP>
        </ServicesCard>
        
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2 className="h2-azul">Corporate Bonds</ServicesH2>
          <ServicesP>
          Earn Sustainnable yield through our bonds vaults.
          </ServicesP>
        </ServicesCard>


        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2 className="h2-azul">Real Estate</ServicesH2>
          <ServicesP>
           Our protocol secure financing to future real estate NFT owners.
          </ServicesP>
        </ServicesCard>
        

      <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2 className="h2-azul">Musical Royalties</ServicesH2>
          <ServicesP>
          Here's a glimpse of the future of Music Industry
          </ServicesP>
        </ServicesCard>
        
  
      

        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2 className="h2-azul">Legal Claims</ServicesH2>
          <ServicesP>
          Tokenization creates a more seamless exchange process.
          </ServicesP>
        </ServicesCard>

        


        <ServicesCard>
          <ServicesIcon src={Icon6} />
          <ServicesH2 className="h2-azul">Fine Art</ServicesH2>
          <ServicesP>
          Invest in off chain art with digital assets.
          </ServicesP>
        </ServicesCard>



      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
