import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./infoElements";

export const InfoSectionLight = ({
  id,
  title,
  subtitle,
  text,
  image,
  btnText,
}) => {
  return (
    <>
      <InfoContainer id={id} className="black-bg">
        <InfoWrapper>
          <div className="row expand-row gx-5">

          <div className="col-lg-6 col-sm-12 img-wrap d-none d-sm-block">
              <img src={image} alt="" className="fit-img"></img>
           </div>

   
            <div className="col-lg-6 col-sm-12 my-auto px-5">
              <Subtitle>{subtitle}</Subtitle>
              <Title >{title}</Title>
              <p className="mb-4">{text}</p>
          
            </div>

            <div className="col-lg-6 col-sm-12 img-wrap d-block d-sm-none">
              <img src={image} alt="" className="fit-img"></img>
           </div>

          
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSectionLight;
