import styled from "styled-components";

export const NewsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
   
    border-bottom:1px solid #111;
 

`

export const NewsWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 20px 50px;
    display: inline-block;
  
    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 50px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr ;
        //padding: 0 20px;
        padding: 20px 50px;
    }

`

export const NewsH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 14px;
    text-align:center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
       
    }

`

export const NewsH2 = styled.h2`
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: normal;
    color:#666;

    @media screen and (max-width: 768px) {
       text-align:center;
    }
`
