import React from "react";
import { RoadmapContainer, RoadmapH1, RoadmapWrapper, RoadmapCard, RoadmapH2, RoadmapP } from "./RoadmapElements";
import Icon1 from "../../assets/icons/icons8-realestate.png"
import Icon2 from "../../assets/icons/icons8-corpbonds.png"
import Icon3 from "../../assets/icons/icons8-gov.png"
import Icon4 from "../../assets/icons/icons8-legal.png"
import Icon5 from "../../assets/icons/icons8-musical.png"
import Icon6 from "../../assets/icons/icons8-fineart.png"

const Services = () => {
  return (
    <RoadmapContainer id="roadmap">
      <RoadmapH1>Roadmap</RoadmapH1>
      
      <RoadmapWrapper>

        <RoadmapCard>
          <RoadmapH2>Launch</RoadmapH2>
          <RoadmapP className="h2-azul">Q4 / 2022</RoadmapP>
         <div className="my-auto px-auto text-center ">
            <ul className="navbar-nav">
              <li className="nav-item nav-rodamap py-1">Foundation incorporation </li>
              <li className="nav-item nav-rodamap py-1">UI/UX Release</li>
              <li className="nav-item nav-rodamap py-1">Whitepaper v1 Release</li>
              <li className="nav-item nav-rodamap py-1">Stake LP v1 TestNet</li>
            </ul>
         </div>
        </RoadmapCard>

        <RoadmapCard>
          <RoadmapH2>Tests</RoadmapH2>
          <RoadmapP className="h2-azul">Q1 / 2023</RoadmapP>
         <div className="my-auto px-auto  text-center">
            <ul className="navbar-nav">
              <li className="nav-item nav-rodamap py-1">ALTDAO Governance v1</li>
              <li className="nav-item nav-rodamap py-1">Assets Acquisitions </li>
              <li className="nav-item nav-rodamap py-1">Lightpaper v1 Release</li>
              <li className="nav-item nav-rodamap py-1">Stake LP v1 Beta Release</li>
           </ul>
         </div>
        </RoadmapCard>

        <RoadmapCard>
          <RoadmapH2>First IDO</RoadmapH2>
          <RoadmapP className="h2-azul">Q2 / 2023</RoadmapP>
         <div className="my-auto px-auto text-center">
            <ul className="navbar-nav">
              <li className="nav-item nav-rodamap py-1">Audit Smart Contracts</li>
              <li className="nav-item nav-rodamap py-1">ALT Community (IDO) </li>
              <li className="nav-item nav-rodamap py-1">LP v1 Public Release </li>
              <li className="nav-item nav-rodamap py-1">Security Hotfixes</li>
            </ul>
         </div>
        </RoadmapCard>

        <RoadmapCard>
          <RoadmapH2>Governance</RoadmapH2>
          <RoadmapP className="h2-azul">Q3 / 2023</RoadmapP>
         <div className="my-auto px-auto text-center">
            <ul className="navbar-nav">
              <li className="nav-item nav-rodamap py-1">Fees to DAO members </li>
              <li className="nav-item nav-rodamap py-1">Assets from Public Sale</li>
              <li className="nav-item nav-rodamap py-1">MarketPlace NFTR Beta Release </li>
              <li className="nav-item nav-rodamap py-1">LP v2 Beta Release </li>
            </ul>
         </div>
        </RoadmapCard>

        <RoadmapCard>
          <RoadmapH2>Public</RoadmapH2>
          <RoadmapP className="h2-azul">Q4 / 2023</RoadmapP>
         <div className="my-auto px-auto text-center">
            <ul className="navbar-nav">
              <li className="nav-item nav-rodamap py-1">Security hotfixes</li>
              <li className="nav-item nav-rodamap py-1">Audit Smart Contracts LP v2</li>
              <li className="nav-item nav-rodamap py-1">NFTR Public Release </li>
              <li className="nav-item nav-rodamap py-1">LP v2 Public Release</li>
           </ul>
         </div>
        </RoadmapCard>

       

      </RoadmapWrapper>
    </RoadmapContainer>
  );
};

export default Services;

<iframe width="560" height="580" 
src="https://app.pipefy.com/public/form/HoX8fL8E?embedded=true%22" frameborder="0"></iframe>
