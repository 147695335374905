import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroBtnWrapper,
  
} from "./HeroElements";
import { ButtonR } from "../ButtonElements";
import Video from "../../assets/images/ALT_stablerender3.jpg";
import "../../App.css";


const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">

      <HeroBg>
        <VideoBg src={Video} className="test" />
      </HeroBg> 
    
      <HeroContent>
     <div className="row expand-row gx-5">

            <div className="col-lg-8 col-sm-12 my-auto px-xs-0 px-lg-5">
           
             <h1 className="h1-hero">Real-World <br />Assets on-chain</h1>
             <p className="hero-text">Our platform enables anyone, anywhere to invest in yield-bearing real assets, and earn the interest. </p>
            
              {/* <HeroBtnWrapper>
                <ButtonR to="" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                  Coming Soon
                </ButtonR>
              </HeroBtnWrapper> */}
            
            </div>

            <div className="col-lg-4 col-sm-12">
              {/* <img src={imageUrl} alt="" className="fit-img"></img> */}
            </div> 

      </div>
     </HeroContent>
 </HeroContainer>
  );
};

export default HeroSection;
