import styled from "styled-components";

export const FooterContainer = styled.div`
    padding-top: 30px;
    padding-bottom: 15px;

`

export const FooterWrapper = styled.div`
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        text-align:center;
    }

`

export const FooterH2 = styled.h2`
    font-size: 1.3rem;
    color: white;
    font-weight: 500;
    margin-bottom: 3px;

    @media screen and (max-width: 768px) {
        margin-top: 13px;
    }

`

export const FooterLink = styled.a`
    font-size: 0.9rem;
    text-decoration: none;
    color: grey;
    transition: all 0.3s;
    position:relative;
    cursor: pointer;
    &:hover {
        color: #007AF8;
    }


    @media screen and (max-width: 768px) {
        text-align:center;
    }

`

export const FooterLinkToolTip = styled.span`
    visibility: hidden;
    width: 120px;
    background-color: #222;
    border: 1px solid #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top:-6px;
    margin-left:10px;
    position: absolute;
    z-index: 1;

`
