import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages";
import SigninPage from "./pages/signin";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const TRACKING_ID = "G-Y4H59C511X"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const tagManagerArgs = {
    gtmId: 'G-Y4H59C511X'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (

   <section>
   <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/signin" element={<SigninPage />}></Route>
    </Routes >
    </Router>

    </section>
  );
}



export default App;
