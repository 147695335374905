import React, { useState } from "react";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import HeroSection from "../components/heroSection";
import Alt_Backedby from "../assets/images/ALT_stablerender_backed.jpg";
import Alt_NFT from "../assets/images/ALT_stablerender_nft.jpg";
import Alt_Block from "../assets/images/ALT_stablerender_alt.jpg";
import InfoSection from "../components/infoSection";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Roadmap from "../components/roadmap";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";


export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />

      <Services />


      <InfoSection
        image={Alt_Backedby}
        id="solution"
        title="Stablecoin backed by real-world assets"
        text="Our stablecoin system will drive the path to bind on chain assets from the real-world, acquiring, maintaining, and liquidating those assets off chain."
       
      />

      <InfoSectionLight
        image={Alt_Block}
         title="Assets on-chain through NFT Marketplace"
        text="Users from all around the world can make an offer to acquire the assets from the protocol. Invest in a simple and accessible way."
       
      />

      <InfoSection
        image={Alt_NFT}
       title="Institutional-Grade service providers"
        text="Our vision is a currency system with a trustworthy process of bringing real value to back a decentralized currency. "
       
      />

      <Roadmap />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Home;