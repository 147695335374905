import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md"

export const HeroContainer = styled.div`
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
    //height: 800px;
    height: 65vh;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    @media screen and (max-width: 768px) {
        height: 58vh;
        padding: 0;
    }
 
`

export const HeroBg = styled.div`
    position: absolute;
    top:-80px;
    right: 0;
    bottom: 0;
    left:  0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index:1;
    @media screen and (max-width: 768px) {
        position: relative;
       
    }

`

export const VideoBg = styled.img`
    width: 100%;
    height: 100%;
    
    --o-object-fit: cover;
    object-fit: cover;
    background: #10111A;
    opacity: 1;
    
    @media screen and (max-width: 768px) {
        width: 112%;
        height: 76%;
        margin-left:-50px;
    }
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin-top:100px;
    }

    
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: left;
    align-items: left;

`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;

`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;

`

