import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'
import {NavLogo,NavLinks } from "../navbar/NavbarElements";
import AltBlock  from "../../assets/images/ALTBLOCK_blue.svg";

export const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>

                <NavLogo to="/">
                 <NavLinks to="home">
                    <img src={AltBlock} width="150" /> 
                    </NavLinks>
                </NavLogo>
                
                <SidebarMenu>
                
                    <SidebarLink to="realassets" onClick={toggle}>
                    Real Assets
                    </SidebarLink>
                    <SidebarLink to="solution" onClick={toggle}>
                    Solutions
                    </SidebarLink>
                    <SidebarLink to="roadmap" onClick={toggle}>
                    Roadmap
                    </SidebarLink>
                    

                </SidebarMenu>
            
            </SidebarWrapper>
        </SidebarContainer>
    )
}