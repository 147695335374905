import React from "react";
import { NewsContainer, NewsWrapper, NewsH1, NewsH2 } from "./NewsletterElements";
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "//altstreet.us14.list-manage.com/subscribe/post?u=dac7eb89aa0996eea2aba416e&amp;id=c42ee169cd";


// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

const Newsletter = () => {
  return (
    <NewsContainer id="newsletter">
      <NewsH1>Newsletter</NewsH1>
      <NewsH2>sign up to receive email updates on new product announcements, promotions, and more.</NewsH2>
      <NewsWrapper className="newsform">

      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <div className="newsform-message">
            <SimpleForm onSubmitted={formData => subscribe(formData)} />
            {status === "sending" && <span >sending...</span>}
            {status === "error" && <span dangerouslySetInnerHTML={{__html: message}}/>}
            {status === "success" && <span >Subscribed !</span>}
          </div>
        )}
      />
       

      </NewsWrapper>
    </NewsContainer>
  );
};

export default Newsletter;
